<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  updated() {},
};
</script>

<template>
  <div class="3xl:container 3xl:mx-auto max-w-screen-3xl">
    <div
      class="w-full h-32 pt-10 pb-8 text-white bg-black border-t-2 sm:h-10 sm:pt-5 min-[48px]:h-10 min-[48px]:pt-2"
    >
      <div
        class="grid justify-around items-center grid-flow-col grid-rows-1 gap-10 sm:gap-1 min-[48px]:gap-1"
      >
        <img src="@/assets/images/polcker-progressbar-white.svg" alt="white" />
        <img src="@/assets/images/polcker-progressbar-white.svg" alt="white" />
        <img src="@/assets/images/polcker-progressbar-white.svg" alt="white" />
        <img src="@/assets/images/polcker-progressbar-white.svg" alt="white" />
        <img src="@/assets/images/polcker-progressbar-white.svg" alt="white" />
        <img src="@/assets/images/polcker-progressbar-white.svg" alt="white" />
        <img src="@/assets/images/polcker-progressbar-white.svg" alt="white" />
        <img src="@/assets/images/polcker-progressbar-gree.svg" alt="gree" />
        <img src="@/assets/images/polcker-progressbar-gree.svg" alt="gree" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
